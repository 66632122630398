export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    UserRoles = 4,
    Projects = 5,
    Audit = 6,
    AccessRequests = 7,
    Teams = 8,
    Claims = 9,
    Members = 10,
    Insights = 11,
    Pipelines = 12,
    Surveys = 13,
}
