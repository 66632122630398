import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { sortByProperty } from '@mt-ng2/common-functions';

import { IState } from '@model/interfaces/state';
import { ICountry } from '@model/interfaces/country';
import { IMetaItem } from '@model/interfaces/base';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    private _states: IState[];
    private _countries: ICountry[];
    private _gradingLetters: IMetaItem[];
    private _projectTypes: IMetaItem[];
    private _gradingCompetencies: IMetaItem[];

    constructor(private http: HttpClient) {}

    getStates(): Observable<IState[]> {
        if (!this._states) {
            return this.http.get<IState[]>('/options/states').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    this._states = answer;
                }),
            );
        } else {
            return of(this._states);
        }
    }

    getCountries(): Observable<ICountry[]> {
        if (!this._countries) {
            return this.http.get<ICountry[]>('/options/countries').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    const indexOfUS = answer.findIndex((country) => country.CountryCode === 'US');
                    answer.splice(0, 0, answer.splice(indexOfUS, 1)[0]);
                    this._countries = answer;
                }),
            );
        } else {
            return of(this._countries);
        }
    }

    GetGradingLetters(): Observable<IMetaItem[]> {
        if (!this._gradingLetters) {
            return this.http.get<IMetaItem[]>('/options/scoreItems').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    this._gradingLetters = answer;
                }),
            );
        } else {
            return of(this._gradingLetters);
        }
    }

    GetProjectTypes(): Observable<IMetaItem[]> {
        if (!this._projectTypes) {
            return this.http.get<IMetaItem[]>('/options/projectTypes').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    this._projectTypes = answer;
                }),
            );
        } else {
            return of(this._projectTypes);
        }
    }

    GetGradingCompetencies(): Observable<IMetaItem[]> {
        if (!this._gradingCompetencies) {
            return this.http.get<IMetaItem[]>('/options/gradingCompetencies').pipe(
                tap((answer) => {
                    this._gradingCompetencies = answer.sort((a, b) => (b.Id < a.Id ? -1 : b.Id > a.Id ? 0 : 1));
                }),
            );
        } else {
            return of(this._gradingCompetencies);
        }
    }

}
