import { userPathSlugs } from './user-paths.library';

export const appPathSlugs = {
    myProfile: 'my-profile',
};

export const appPaths = {
    adminAccess: 'adminaccess',
    forgotPassword: 'forgotpassword',
    home: 'home',
    login: 'login',
    myProfile: `${userPathSlugs.root}/${appPathSlugs.myProfile}`,
    resetPassword: 'resetpassword',
};
